import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Heading, Map } from '../../components';

import css from './ListingPage.module.css';

const SectionMapMaybe = ({
  className,
  rootClassName,
  geolocation,
  publicData,
  listingId,
  mapsConfig,
}) => {
  const [isStatic, setIsStatic] = useState(true);

  const [selectedAddress, setSelectedAddress] = useState(
    publicData?.location?.address || ''
  );

  if (!geolocation) {
    return null;
  }

  const additionalLocations = publicData?.additionalLocations ?? [];

  const allLocations = [
    {
      location: publicData?.location, geolocation,
      geolocation,
      building: publicData?.building,
    },
    ...additionalLocations,
  ];
  
  const selectedLocation = allLocations.find(
    locationData => locationData?.location?.address === selectedAddress
  );

  const selectedGeolocation = selectedLocation?.geolocation;

  // Add a conditional check to handle the case when allLocations is empty
  if (!selectedLocation) {
    return null;
  }

  const classes = classNames(rootClassName || css.sectionMap, className);
  const cacheKey = listingId
    ? `${listingId.uuid}_${selectedGeolocation.lat}_${selectedGeolocation.lng}`
    : null;

  const mapProps = mapsConfig.fuzzy.enabled
    ? {
        obfuscatedCenter: obfuscatedCoordinates(
          selectedGeolocation,
          mapsConfig.fuzzy.offset,
          cacheKey
        ),
      }
    : { address: selectedAddress, center: selectedGeolocation };

  const map = <Map {...mapProps} useStaticMap={isStatic} />;

  return (
    <section className={classes} id="listing-location">
      <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.locationTitle" />
      </Heading>
      <ol className={css.additionalLocations}>
        {allLocations.map(({ location: { address } }) => {
          return (
            <li
              key={address}
              className={classNames(css.additionalLocation, {
                [css.additionalLocationActive]: selectedAddress === address,
              })}
            >
              <button onClick={() => setSelectedAddress(address)}>{address}</button>
            </li>
          );
        })}
      </ol>

      {isStatic ? (
        <button className={css.map} onClick={() => setIsStatic(false)}>
          {map}
        </button>
      ) : (
        <div className={css.map}>{map}</div>
      )}
    </section>
  );
};

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
