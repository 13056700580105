import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SectionDamageDepositMaybe = props => {
  const { formattedDamageDeposit } = props;

  return formattedDamageDeposit ? (
    <div className={css.sectionDamageDeposit}>
      <h2 className={css.damageDepositTitle}>
        <FormattedMessage id="ListingPage.damageDepositTitle" />
      </h2>
      <p className={css.damageDeposit}>
        <FormattedMessage
          id="ListingPage.damageDepositDescription"
          values={{
            price: (
              <span className={css.damageDepositPrice}>
                {formattedDamageDeposit}
              </span>
            ),
          }}
        />
      </p>
    </div>
  ) : null;
};

SectionDamageDepositMaybe.defaultProps = {
  formattedDamageDeposit: null,
};

SectionDamageDepositMaybe.propTypes = {
  formattedDamageDeposit: string,
};

export default SectionDamageDepositMaybe;
